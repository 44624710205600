.user-status {
  position: relative;
  margin: 0 0.2em 0 0.2em;

  > .user-status-item.wide {
    width: 20%;
  }

  > .user-status-item {
    box-sizing: border-box;
    float: left;
    margin-right: 0.5%;
    position: relative;
    font-size: 11px;
    line-height: 120%;
    border-radius: 3px;
    background-color: #ccc;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 15px 5px 15px 5px;
    height: 55px;
    margin-top:0.4em;

    &:before {
      all: revert;
    }

    &:after {
      all: revert;
    }

    &[passed] {
      background-color: @ok-color;
      color: white;
    }
    &[started] {
      background: @semi-ok-color;
      color: black;
    }
    &[warning] {
      background: @warning-color;
      color: black;
    }
    &[failure] {
      background-color: @box-bg-color;
      color: black;
    }
    .status-title {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.goal-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0.2em;
}
#competencyreport {
  .filterbx {
    width: 20em!important;
  }
}
.add-button:hover {
  cursor: pointer;
}
form.fForm .vTextInput {
  width: 100%!important;
}
.hRadioWidget label {
  margin-right: 1em;
}
a.cr-link {
  color: inherit;
  &:hover {
    text-decoration: none!important;
    cursor: pointer;
  }
}
.dvTimeInput {
  display: flex;
  margin-bottom: 1em;

  #id_finished_time_hr,  #id_finished_time_min{
    width: 8ex;
    text-align: right;
    padding-right: o.5em;
    margin-right: 4px;
  }
}
.ownpage {
    &[passed] {
        color: @ok-color;
    }
}