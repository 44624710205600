.competency-plan-profile {

  h2 {
    padding-top: 2rem;
  }

  table {

    th {
      white-space: nowrap;
      font-weight: 600;
    }

  }

  &-goal-name {
    font-size: inherit;
    font-weight: normal;
    margin-bottom: 0;
  }

  &-goal-deadline {
    width: 20%;
  }

  &-goal-pct-completed {
    width: 10%;
  }

  &-add-result {

    width: 20%;

    a {
      white-space: nowrap;
    }
  }

}
