.user-status {
  position: relative;
  margin: 0 0.2em 0 0.2em;
}
.user-status > .user-status-item.wide {
  width: 20%;
}
.user-status > .user-status-item {
  box-sizing: border-box;
  float: left;
  margin-right: 0.5%;
  position: relative;
  font-size: 11px;
  line-height: 120%;
  border-radius: 3px;
  background-color: #ccc;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px 5px 15px 5px;
  height: 55px;
  margin-top: 0.4em;
}
.user-status > .user-status-item:before {
  all: revert;
}
.user-status > .user-status-item:after {
  all: revert;
}
.user-status > .user-status-item[passed] {
  background-color: #007E84;
  color: white;
}
.user-status > .user-status-item[started] {
  background: #007e847a;
  color: black;
}
.user-status > .user-status-item[warning] {
  background: #f1f360;
  color: black;
}
.user-status > .user-status-item[failure] {
  background-color: #ccc;
  color: black;
}
.user-status > .user-status-item .status-title {
  overflow: hidden;
  text-overflow: ellipsis;
}
.goal-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0.2em;
}
#competencyreport .filterbx {
  width: 20em!important;
}
.add-button:hover {
  cursor: pointer;
}
form.fForm .vTextInput {
  width: 100%!important;
}
.hRadioWidget label {
  margin-right: 1em;
}
a.cr-link {
  color: inherit;
}
a.cr-link:hover {
  text-decoration: none!important;
  cursor: pointer;
}
.dvTimeInput {
  display: flex;
  margin-bottom: 1em;
}
.dvTimeInput #id_finished_time_hr,
.dvTimeInput #id_finished_time_min {
  width: 8ex;
  text-align: right;
  padding-right: o 0.5em;
  margin-right: 4px;
}
.ownpage[passed] {
  color: #007E84;
}
.competency-plan-profile h2 {
  padding-top: 2rem;
}
.competency-plan-profile table th {
  white-space: nowrap;
  font-weight: 600;
}
.competency-plan-profile-goal-name {
  font-size: inherit;
  font-weight: normal;
  margin-bottom: 0;
}
.competency-plan-profile-goal-deadline {
  width: 20%;
}
.competency-plan-profile-goal-pct-completed {
  width: 10%;
}
.competency-plan-profile-add-result {
  width: 20%;
}
.competency-plan-profile-add-result a {
  white-space: nowrap;
}
